exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-commercial-js": () => import("./../../../src/pages/commercial.js" /* webpackChunkName: "component---src-pages-commercial-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-guarantee-js": () => import("./../../../src/pages/guarantee.js" /* webpackChunkName: "component---src-pages-guarantee-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-local-experts-js": () => import("./../../../src/pages/local-experts.js" /* webpackChunkName: "component---src-pages-local-experts-js" */),
  "component---src-pages-portfolio-js": () => import("./../../../src/pages/portfolio.js" /* webpackChunkName: "component---src-pages-portfolio-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-single-expert-js": () => import("./../../../src/templates/single-expert.js" /* webpackChunkName: "component---src-templates-single-expert-js" */),
  "component---src-templates-single-landing-js": () => import("./../../../src/templates/single-landing.js" /* webpackChunkName: "component---src-templates-single-landing-js" */),
  "component---src-templates-single-portfolio-js": () => import("./../../../src/templates/single-portfolio.js" /* webpackChunkName: "component---src-templates-single-portfolio-js" */),
  "component---src-templates-single-post-js": () => import("./../../../src/templates/single-post.js" /* webpackChunkName: "component---src-templates-single-post-js" */),
  "component---src-templates-single-service-js": () => import("./../../../src/templates/single-service.js" /* webpackChunkName: "component---src-templates-single-service-js" */)
}

